import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d6c163e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "layout" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_tabbar = _resolveComponent("tabbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component }) => [
        (_openBlock(), _createBlock(_KeepAlive, { include: _ctx.includeList }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
        ], 1032, ["include"]))
      ]),
      _: 1
    }),
    (_ctx.$route.meta.tabbar)
      ? (_openBlock(), _createBlock(_component_tabbar, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}