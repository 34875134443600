
import { Options, Vue } from "vue-property-decorator";
import * as api from "@/api/user";

@Options({})
export default class extends Vue {
  mounted() {
    console.log(123);

    api.userIsDefaultPwd().then((res) => {
      if (res.data) {
        this.$dialog
          .alert({
            message: "请修改默认密码",
          })
          .then(() => {
            this.$router.push({ path: "/passworld" });
          });
      }
    });
  }
}
